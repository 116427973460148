<template>
  <List :key="listReloadCounter" @listReload="reloadList" :gslocation="gsLocation" :predictdays="predictDays" :minelev="minElev" :style="{ 'display' : (selectedTab == 'List') ? 'block' : 'none' }" class="component-window"/>
  <Current :key="reinitCounter" :gslocation="gsLocation" :style="{ 'display' : (selectedTab == 'Current') ? 'block' : 'none' }" class="component-window"/>
  <Settings
    @newDayCount="newDayCount" @newLocation="newLocation" @newElev="newElev" @reinitTabs="reinitTabs()"
    :oldDayCount="predictDays" :oldLocation="gsLocation" :oldElev="minElev"
    v-if="selectedTab == 'Settings'" class="component-window"
  />
  <Menu @SettingsTab="selectedTab = 'Settings'" @CurrentTab="selectedTab = 'Current'" @ListTab="selectedTab = 'List'" class="main-menu"/>
  <Legal />
</template>

<script>

import List from './components/List.vue'
import Current from './components/Current.vue'
import Settings from './components/Settings.vue'
import Menu from './components/Menu.vue'
import Legal from './components/Legal.vue'

export default {
  name: 'PassPredict',
  components: {
    List,
    Current,
    Settings,
    Menu,
    Legal
  },
  data() {
    return {
      selectedTab: "List",
      gsLocation: { lat: 48.25293916062294, lng: 11.659468046856894, alt: 500 },
      predictDays: 10,
      minElev: 10,
      reinitCounter: 0,
      listReloadCounter: 0
    }
  },
  methods: {
    reinitTabs() {
      this.reinitCounter++
      this.listReloadCounter++
    },
    reloadList() {
      this.listReloadCounter++
    },
    newDayCount(newValue) {
      this.predictDays = newValue
    },
    newLocation(newValue) {
      this.gsLocation = newValue
    },
    newElev(newValue) {
      this.minElev = newValue
    },
  }
}
</script>

<style>

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #575757;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}
.component-window {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 90vh;
  border: none;
}
.main-menu {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
