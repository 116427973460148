<template>
  <div class="settings-container">
    <h1>Settings</h1><br>
    Prediction Length
    <div class="slidercontainer">
      <input v-model="predictDaysLocal" type="range" min="1" max="10" class="slider">
      {{predictDaysLocal}} {{(predictDaysLocal == 1) ? 'day' : 'days'}}
    </div><br>
    Min. Elevation
    <div class="slidercontainer">
      <input v-model="minElevLocal" type="range" min="0" max="89" class="slider">
      {{minElevLocal}} °
    </div><br>
    Ground station location
    <div class="location-input">
      <div class="location-coord">
        <span>Lat.</span><input v-model="gsLocationLocal.lat" class="ll-input">
      </div>
      <div class="location-coord">
        <span>Lon.</span><input v-model="gsLocationLocal.lng" class="ll-input">
      </div>
      <div class="location-coord">
        <span>Alt.</span><input v-model="gsLocationLocal.alt" class="ll-input">
      </div><br>
      <button class="applybutton" @click="gsLocationApply()">Apply</button>
    </div><br><br><br><br><br>
    <div style="text-align: left; font-size: 12px;">Disclaimer: <br>
    This app uses the N2YO API (https://n2yo.com/api) to get predictions on passes.<br>
    This app uses the website https://satvis.space/ to view the current position of the satellites.<br>
    Prediction accuracy and availability of services may vary.<br>
    All times are local time with respect to the time zone of the end user's device.
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPreferences',
  props: [ 'oldDayCount', 'oldLocation', 'oldElev' ],
  data() {
    return {
      predictDaysLocal: 10,
      gsLocationLocal: { lat: 48.266290319508634, lng: 11.668435178078305, alt: 500 },
      minElevLocal: 0
    }
  },
  methods: {
    // applies all settings
    gsLocationApply() {
      this.$emit('newLocation', this.gsLocationLocal)
      this.$emit('newDayCount', this.predictDaysLocal)
      this.$emit('newElev', this.minElevLocal)
      this.$emit('reinitTabs')
    },
  },
  mounted() {
    this.predictDaysLocal = this.oldDayCount
    this.gsLocationLocal = this.oldLocation
    this.minElevLocal = this.oldElev
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slider {
  width: 80%;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: #849e96;
  margin-top: 15px;
  margin-bottom: 20px;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer; 
  border-radius: 12.5px;
}

.slider::-moz-range-thumb {
  width: 25px; 
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
  border-radius: 12.5px;
}
.slidercontainer {
  text-align: left;
  padding-left: 10px;
}
.settings-container {
  position: fixed;
  width: 100vw;
  height: 86vh;
  background-color: #03092b;
  background: radial-gradient(circle, rgb(17, 23, 22) 0%, rgb(24, 37, 37) 100%);
  color: #e0e0e0;
  font-size: 20px;
  overflow-y: scroll;
}
.location-input {
  width: 100%;
  height: 20px;
  margin-top: 30px;
  text-align: center;
}
.location-coord {
  text-align: left;
  width: 70%;
  margin: 0 15%;
  max-width: 400px;
}
.ll-input {
  float: right;
}
.applybutton {
  float: right;
  border-radius: 3px;
  border: 1px solid #79c4ab;
  background-color: #79c4ab;
  color: rgb(17, 23, 22);
  padding: 10px;
  width: 200px;
  margin: 10px 40px;
  height: 40px;
  font-size: 15px;
  cursor: pointer;
}
.applybutton:active {
  background-color: #dfdf3875;
}
</style>
