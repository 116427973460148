<template>
  <div class="menu-container">
    <button :class="{'active-tab': (currentTab == 'List')}" @click="emitTab('List')">
      <span class="mainmenu-mini">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="mainmenu-icon bi bi-list" viewBox="1.5 3 12 10">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg><br>
      </span>
      <span class="menu-text">List</span>
    </button>
    <button :class="{'active-tab': (currentTab == 'Current')}" @click="emitTab('Current')">
      <span class="mainmenu-mini">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="mainmenu-icon bi bi-pin-map" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
          <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
        </svg><br>
      </span>
      <span class="menu-text">Live</span>
    </button>
    <button :class="{'active-tab': (currentTab == 'Settings')}" @click="emitTab('Settings')">
      <span class="mainmenu-mini">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="mainmenu-icon bi bi-gear" viewBox="0 0 16 16">
          <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
          <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
        </svg><br>
      </span>
      <span class="menu-text">Settings</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BottomMenu',
  data() {
    return {
      currentTab: 'List'
    }
  }, 
  methods: {
    emitTab(tab) {
      this.$emit(tab + 'Tab')
      this.currentTab = tab
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.menu-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 16vh;
  background-color: #182525;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}
button {
  margin: 15px 0 40px 0;
  width: 100px;
  background-color: #2b356268;
  box-shadow: 0 0 5px #6872a168;
  border: none;
  border-radius: 10px;
  color: #a0a0a0;
  line-height: 1.9;
  overflow: hidden;
  cursor: pointer;
}
button:hover {
  box-shadow: 0 0 15px #ffffff48;
  transition: box-shadow 0.15s ease-in;
}
button:active {
  box-shadow: 0 0 15px #ffffff7f;
}
.active-tab {
  background-color: #3a976a80 !important;
}
.mainmenu-icon {
  width: 50%;
  height: 50%;
}
.mainmenu-mini {
  @media (max-height: 655px) {
    display: none;
  }
}
</style>
