<template>
  <div class="current-main">
    <div style="width: 100%; height: 40px;"></div>
    <iframe :src="'/cesium.html?lat=' + gslocation.lat + '&lng=' + gslocation.lng" class="cesium-frame"></iframe>
  </div>
</template>

<script>


export default {
  name: 'CurrentPosition',
  props: [ 'gslocation' ],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.current-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 84vh;
  border: none; 
  background-color: #03092b;
  background: radial-gradient(circle, rgb(17, 23, 22) 0%, rgb(24, 37, 37) 100%);
  text-align: center;
}
.cesium-frame {
  width: calc(100% - 10px);
  height: calc(100% - 50px);
  margin: 0 5px 10px 5px;
  border: none;
}
</style>