<template>
  <div class="list-main">
    <div class="spinner" v-if="!isLoaded">
      <div class="loadingio-spinner-rolling-yq3hr7owa29"><div class="ldio-3r2bqht338w">
      <div></div>
      </div></div>
    </div>
    <div v-if="isLoaded" class="list-menu">
      <div class="list-choice">
        <button @click="forceComponentUpdate()" class="choice-button reload-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 13">
            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
          </svg>
        </button>
        <button :disabled="apiError.hasError" @click="resetShown()" class="choice-button" :class="{'choice-button-current' : isAllactive}">All</button>
        <button @click="invertShown(sat.info.satid)" class="choice-button" :class="{'choice-button-current' : sat.isShown}" v-for="sat in response" :key="sat.info.satid">{{sat.info.satname}}</button>
      </div>
    </div>
    <div v-if="isLoaded" class="list-container">
      <div v-if="apiError.hasError" class="pass-accordion" :style="{
        'height' : 'fit-content',
        'line-height' : 1.5,
        'background-color' : '#8a2f2f68',
        'box-shadow' : '0 0 5px #90383868',
      }">{{apiError.errorMessage}}</div>
      <div v-if="!apiError.hasError" class="list-head" >
        <div style="width: 15px;"></div> <!-- for alignment, do not remove -->
        <div style="width: 110px;">AOS Time</div>
        <div style="font-size: 15px; width: 80px;">Name</div>
        <div style="width: 50px;">Max. °</div><br>
      </div>
      <div v-for="(pass, index) in shownPasses" :key="index">
        <div v-if="typeof pass === 'string'" class="date-divider">
          {{formatDivider(pass)}} 
          <div class="date-divider-dash">
            <div class="date-divider-dash-middle"></div>
          </div>
        </div>
        <div
          @click="toggleOpenPass(index)"
          v-if="(typeof pass !== 'string') && pass.isShown"
          class="pass-accordion"
          :class="{ 'pass-accordion-open': pass.openInList }"
          :style="{'box-shadow' : '0 0 5px ' + mapTimeToColor(pass.startUTC, pass.windSpeed, pass.windGusts, pass.satrec.jdsatepoch), 'background-color' : mapTimeToColor(pass.startUTC, pass.windSpeed, pass.windGusts, pass.satrec.jdsatepoch)}"
        >
          <button class="expand-button">
            <svg :class="{ 'button-caret-open': pass.openInList }" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class=" button-caret bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          </button>
          <div style="width: 110px;">{{getLocalTime(pass.startUTC)}}</div>
          <div style="font-size: 15px; line-height: 18px; width: 80px;">{{pass.satname}}</div>
          <div style="width: 50px;">{{pass.maxEl.toFixed(2)}}</div><br>
        </div>
        <div class="accordion-open-content" v-if="pass.openInList">
          <!-- LEFT -->
          <div style="width: 45%; height: 100%; float: left; padding-top: 25px; position: relative;">
            <div class="flextable-item"><span><span style="color: #00ff00">⬤ </span>AOS</span><span>{{getLocalTime(pass.startUTC)}}</span></div>
            <div class="flextable-item"><span><span style="color: #ff0000">⬤ </span>LOS</span><span>{{getLocalTime(pass.endUTC)}}</span></div>
            <div class="flextable-item" style="margin-top: 5px;"><span>Azm. Start</span><span>{{pass.startAz}}</span></div>
            <div class="flextable-item"><span>Azm. Max</span><span>{{pass.maxAz}}</span></div>
            <div class="flextable-item"><span>Azm. End</span><span>{{pass.endAz}}</span></div>
            <div style="margin-top: 5px;" :class="{'flextable-item': true, 'high-wind': (pass.windSpeed >= 50 || pass.windGusts >= 50)}"><span>Wind</span><span>{{(pass.windSpeed >= 0) ? pass.windSpeed : "-"}}/{{(pass.windGusts >= 0) ? "G" + pass.windGusts : "-"}}</span></div>
            <div class="epoch-indicator">
              epoch {{getEpochDifference(pass.startUTC, pass.satrec.jdsatepoch).toUpperCase()}},<br>
              {{(pass.inEclipse) ? 'eclipse since ~' + pass.timeSinceDNLine + 'min' : 'sunlight since ~' + pass.timeSinceDNLine + 'min'}}</div>
          </div>
          <!-- RIGHT -->
          <div style="width: 55%; height: 100%; float: right; overflow: hidden; position: relative;">
            <img id="compass-bg" src="../assets/img/compass-bg-nomid.png" class="compass-bg">
            <!-- LEGACY INDICATORS -->
            <!-- <img src="../assets/img/aos-ind.png" class="compass-bg" :style="{ 'rotate' : pass.startAz + 'deg' }">
            <img src="../assets/img/los-ind.png" class="compass-bg" :style="{ 'rotate' : pass.endAz + 'deg' }"> -->

            <!-- Pass path -->
            <div
            v-for="(point, p) in pass.path"
            :key="p"
              :style="{
                'position': 'absolute',
                'background': point.color,
                'width': '2px',
                'height': '2px',
                'border-radius': '1px',
                'top': 'calc(88.25px - ' + Math.cos(point.azm) * (90 - toDegrees(point.elev)) / 90 * 80 + 'px)', //88.25px center
                'right': 'calc(95.1px - ' + Math.sin(point.azm) * (90 - toDegrees(point.elev)) / 90 * 80 + 'px)' //95.1px center
              }"
            ></div>
            <div
              v-if="pass.windDirection > -1"
              :style="{
                'position': 'absolute',
                'rotate': pass.windDirection + 'deg',
                'top': '80.25px', //88.25px center
                'right': '91.1px', //95.1px center
                'color': (pass.windSpeed >= 50 || pass.windGusts >= 50) ? '#ff0000aa' : '#0088ffaa'
              }"
            >⤊</div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 40px; width: 100%"></div>
    </div>
  </div>
</template>

<script>
const SunCalc = require('suncalc3')
const satLib = require('satellite.js')

export default {
  name: 'OverpassList',
  data() {
    return {
      satIDs: [ 
        { name: 'MOVE-II', id: '43780', shownByDefault: true }
        /* { name: 'MOVE-IIB', id: '44398', shownByDefault: false } */
      ],
      isLoaded: false,
      response: [],
      passList: [],
      shownPasses: [],
      TLE: [],
      moveTLE: [],
      windData: [],
      apiError: {
        hasError: false,
        errorMessage: ''
      },
      waitForResponse: undefined,
      waitForTLE: undefined
    }
  },
  props: [ 'gslocation', 'predictdays', 'minelev' ],
  computed: {
    isAllactive() {
      var ret = true
      for (var i = 0; i < this.response.length; i++) {
        if (this.response[i].isShown == false) { ret = false }
      }
      return ret
    }
  },
  methods: {
    toRadians(alpha) {
      return alpha * Math.PI / 180
    },
    toDegrees(phi) {
      return phi * 180 / Math.PI
    },
    julianToJSDate(jd) {
      var X = parseFloat(jd)+0.5;
      var Z = Math.floor(X); //Get day without time
      var F = X - Z; //Get time
      var Y = Math.floor((Z-1867216.25)/36524.25);
      var A = Z+1+Y-Math.floor(Y/4);
      var B = A+1524;
      var C = Math.floor((B-122.1)/365.25);
      var D = Math.floor(365.25*C);
      var G = Math.floor((B-D)/30.6001);

      //must get number less than or equal to 12)
      var month = (G < 13.5) ? ( G - 1 ) : ( G - 13 );

      //if Month is January or February, or the rest of year
      var year = (month<2.5) ? (C-4715) : (C-4716);
      month -= 1; //Handle JavaScript month format
      var UT = B-D-Math.floor(30.6001*G)+F;
      var day = Math.floor(UT);

      //Determine time
      UT -= Math.floor(UT);//from  w  ww .  j  av  a 2 s.co  m
      UT *= 24;
      var hour = Math.floor(UT);
      UT -= Math.floor(UT);
      UT *= 60;
      var minute = Math.floor(UT);
      UT -= Math.floor(UT);
      UT *= 60;
      var second = Math.round(UT);

      return new Date(Date.UTC(year, month, day, hour, minute, second));
    },
    forceComponentUpdate() {
      this.isLoaded = false
      this.response = []
      this.passList = []
      this.apiError = {
        hasError: false,
        errorMessage: ''
      }
      this.$emit('listReload')
    },
    getEpochDifference(timestamp, jdepoch) {
      var total = parseInt((new Date(timestamp * 1000) - new Date(this.julianToJSDate(jdepoch))) / 1000)
      var sign = '+'
      if (total < 0) {
        sign = '-'
        total = -total
      }
      var days = (Math.floor(total / 86400)).toString().padStart(2, '0')
      total = total - days * 86400
      var hours = (Math.floor(total / 3600)).toString().padStart(2, '0')
      total = total - hours * 3600
      var minutes = (Math.floor(total / 60)).toString().padStart(2, '0')
      total = total - minutes * 60
      var seconds = (Math.floor(total)).toString().padStart(2, '0')
      
      return sign + days + 'd ' + hours + ':' + minutes + ':' + seconds
    },
    mapTimeToColor(timestamp, windSpeed, windGusts, jdepoch) {
      var difference = (new Date(timestamp * 1000) - new Date(this.julianToJSDate(jdepoch))) / 86400000
      var redPart = '00'
      var greenPart = '00'
      var blueAndAlpha = '6368'
      // check wind limit
      if (parseFloat(windSpeed) >= 50 || parseFloat(windGusts) >= 50) {
        redPart = '99'
        blueAndAlpha = '00aa'
      } else {
        // check time since epoch
        if (difference > 4) { 
          redPart = 'ff'
          greenPart = '36'
        } else { 
          if (difference < 0) { 
            redPart = '2b'
            greenPart ='36'
          } else {
            if (difference < 2) {
              redPart = ('00' + parseInt((64 * difference)).toString(16)).slice(-2)
              greenPart = ('00' + parseInt((64 * difference + 54)).toString(16)).slice(-2)
            } else {
              redPart = 'ff'
              greenPart = ('00' + parseInt((200 - 100 * (difference - 2) + 54)).toString(16)).slice(-2)
            }
          }
        }
      }
      return '#' + redPart + greenPart + blueAndAlpha
    },
    propagatePassPath(pass) {
      for (var time = pass.startUTC; time < pass.endUTC; time += 10) {
        var date = new Date(time * 1000);
        var satPosVel = satLib.propagate(pass.satrec, date);
        var gmst = satLib.gstime(date);
        var satPos = satLib.eciToGeodetic(satPosVel.position, gmst);
        var observerGd = {
          latitude: this.toRadians(this.gslocation.lat),
          longitude: this.toRadians(this.gslocation.lng),
          height: this.gslocation.alt / 1000
        }
        var satPosEcf = satLib.eciToEcf(satPosVel.position, gmst)
        var angles = satLib.ecfToLookAngles(observerGd, satPosEcf)
        pass.path.push({
          lat: this.toDegrees(satPos.latitude) - this.gslocation.lat,
          lng: this.toDegrees(satPos.longitude) - this.gslocation.lng,
          azm: angles.azimuth,
          elev: angles.elevation,
          color: '#' + ('00' + parseInt((256 * (time - pass.startUTC) / (pass.endUTC - pass.startUTC) )).toString(16)).slice(-2)
            + ('00' + parseInt((256 * (pass.endUTC - time) / (pass.endUTC - pass.startUTC) )).toString(16)).slice(-2)
            + '00'
        })
      }
      return pass
    },
    propagateEclipse(pass) {
      var checkDate = new Date(pass.startUTC * 1000)
      var et = 0
      var initial = this.isSunlit(checkDate, pass)
      pass.inEclipse = !initial
      var ecl = initial
      while (ecl == initial) {
        et = et + 60
        checkDate.setSeconds(checkDate.getSeconds() - 60)
        ecl = this.isSunlit(checkDate, pass)
      }
      pass.timeSinceDNLine = et / 60
      return pass
    },
    isSunlit(date, pass) {
      const RE = 6378.1 // Earth radius in km
      var satPosVel = satLib.propagate(pass.satrec, date)
      var gmst = satLib.gstime(date)
      var satPos = satLib.eciToGeodetic(satPosVel.position, gmst)
      var lonDeg = this.toDegrees(satPos.longitude)
      var latDeg = this.toDegrees(satPos.latitude)
      var sunPos = SunCalc.getPosition(date, latDeg, lonDeg)
      var theta = this.toDegrees(Math.acos(RE/(RE + satPos.height))) // angle from horizontal to tangent with earth surface at satellite height
      if (sunPos.altitudeDegrees + theta > 0) {
        return true
      }
      return false
    },
    toggleOpenPass(index) {
      var singlePass = this.shownPasses[index]
      if (singlePass.openInList) {
        this.shownPasses[index].openInList = false
        this.shownPasses[index].path = []
      } else {
        this.shownPasses[index] = this.propagatePassPath(singlePass)
        this.shownPasses[index] = this.propagateEclipse(this.shownPasses[index])
        this.shownPasses[index].openInList = true
      }
    },
    async getTLE() {
      // get tle file from celestrak.org
      let url = "https://celestrak.org/NORAD/elements/cubesat.txt";
      try {
        let response = await fetch (url);
        this.TLE = await response.text().then(( str ) => {
          return str.split('\r')
        });
        for (var r = 0; r < this.TLE.length; r++) { this.TLE[r] = this.TLE[r].replace(/[\r\n]/gm, '')}

        // get MOVE tles
        for (var satInfo of this.satIDs) {
          for (var lnum = 0; lnum < this.TLE.length; lnum++) {
            if ( this.TLE[lnum].indexOf('1 ' + satInfo.id + 'U') > -1 ) {
              this.moveTLE.push({
                id: satInfo.id,
                name: this.TLE[lnum - 1],
                tle: [ this.TLE[lnum], this.TLE[lnum + 1] ],
              })
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getWind() {
      // get OpenWeather API response
      let url = "https://api.open-meteo.com/v1/forecast?latitude=48.25293916062294&longitude=11.659468046856894&minutely_15=wind_speed_10m,wind_direction_10m,wind_gusts_10m&timeformat=unixtime&timezone=auto&forecast_days=16";
      try {
        let response = await fetch (url);
        this.windData = await response.json()
        for (var i = 0; i < this.passList.length; i++) {
          if (typeof this.passList[i] === "object" && this.passList[i] !== null) {
            var windDataIndex = 0
            this.passList[i].windSpeed = -1
            this.passList[i].windGusts = -1
            this.passList[i].windDirection = -1
            if (this.windData !== undefined && this.windData.minutely_15.time.slice(-1) >= this.passList[i].startUTC) {
              while (windDataIndex < this.windData.minutely_15.time.length) {
                if (this.windData.minutely_15.time[windDataIndex] >= this.passList[i].startUTC) {
                  this.passList[i].windSpeed = this.windData.minutely_15.wind_speed_10m[windDataIndex].toFixed(1)
                  this.passList[i].windGusts = this.windData.minutely_15.wind_gusts_10m[windDataIndex].toFixed(1)
                  this.passList[i].windDirection = this.windData.minutely_15.wind_direction_10m[windDataIndex].toFixed(1)
                  break;
                } else {
                  windDataIndex++;
                }
              }
            }
          }
        }
        return true;
      } catch (error) {
        console.log(error)
      }
    },
    async redoLists() {
      const satLib = require('satellite.js')

      function compareDates(o1, o2) {
        if ( o1.startUTC < o2.startUTC ){
          return -1;
        }
        if ( o1.startUTC > o2.startUTC ){
          return 1;
        }
        return 0;
      }
      try {
        if (this.response.length > 0 || this.response[0].info.transactionscount < 999) {
          this.showAllSats()
          this.passList = []
          var filteredPassList = []
          // puts filtered in list and adds ids and stuff
          for (var singleRes of this.response) {
            if (singleRes.info.passescount > 0 && singleRes.isShown) { 
              for (var singlePass of singleRes.passes) {
                singlePass.satid = singleRes.info.satid
                singlePass.satname = singleRes.info.satname
                singlePass.openInList = false;
                singlePass.path = []
                if (this.moveTLE.length > 0) {
                  singlePass.TLE = this.moveTLE.filter(obj => { return obj.id == singlePass.satid})
                  singlePass.satrec = satLib.twoline2satrec(singlePass.TLE[0].tle[0], singlePass.TLE[0].tle[1])
                }
                filteredPassList.push(singlePass)
              }
            }
          }
          // sorts filtered list
          filteredPassList.sort(compareDates)

          //resets date dividers
          var lastDay = new Date(0)
          var minIsShown = true
          for (var pass of filteredPassList) {
            var day = new Date(pass.startUTC * 1000)
            day.setHours(0,0,0,0)
            if (day > lastDay && minIsShown) { this.passList.push(day.toString()) }
            // make all visible
            pass.isShown = true
            this.passList.push(pass)
            lastDay = day
          }
          //remove unneccessary dividers
          var lastType;
          for (var p = 0; p < filteredPassList.length; p++) {
            if (typeof filteredPassList[p] === 'string' && lastType === 'string') { 
              filteredPassList.splice(p-1, 1)
            }
            lastType = (typeof filteredPassList[p])
          }
          // to be sure, and to move list to shownPasses
          this.resetDividers()
          for (var defaultPass of this.satIDs) {
            if (!defaultPass.shownByDefault) this.invertShown(defaultPass.id);
          }
        } else {
          if (this.response[0].info.transactionscount > 999) {
            this.apiError.hasError = true
            this.apiError.errorMessage = 'Error getting data: the momentary transaction limit has been reached, please try again in a few minutes.'
          } else {
            this.apiError.hasError = true
            this.apiError.errorMessage = 'Error getting data: the API server is not responding. Please check your connection and try again.'
          }
        }
      } catch(e) {
        this.apiError.hasError = true
        this.apiError.errorMessage = 'Error getting data: an unexpected error occured while trying to process the data. This could be due to an invalid API response. \n\n (Error details: ' + e + ').'
        console.log(e)
      }
      this.isLoaded = true
    },
    formatDivider(input) {
      var date = new Date(input)
      return date.getFullYear() + '-' + (date.getMonth()+1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0')
    },
    // implement time zones here!!
    getLocalTime(utcstring) {
      var date = new Date(utcstring * 1000)
      return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0')
    },
    showAllSats() {
      for (var i = 0; i < this.response.length; i++) {
        this.response[i].isShown = true;
      }
      for (var j = 0; j < this.passList.length; j++) {
        if (typeof this.passList[j] !== 'string') { this.passList[j].isShown = true }
      }
    },
    resetShown() {
      for (var pass of this.passList) {
        if (typeof pass !== 'string') { pass.isShown = true }
      }
      for (var i = 0; i < this.response.length; i++) { this.response[i].isShown = true }
      this.resetDividers()
    },
    invertShown(id) {
      for (var pass of this.passList) {
        if (typeof pass !== 'string') { 
          pass.openInList = false
          if (id == pass.satid) { 
            pass.isShown = !pass.isShown
          }
        }
      }
      var active = 0;
      for (var i = 0; i < this.response.length; i++) { 
        if (this.response[i].info.satid == id) { this.response[i].isShown = !this.response[i].isShown }
        if (this.response[i].isShown) { active++ }
      }
      if (active < 1) { this.resetShown() }
      this.resetDividers()
    },
    resetDividers() {
      var listWithNewDividers = []
      var lastDay = new Date(0)
      //resets date dividers
      for (var resetter = 0; resetter < this.passList.length; resetter++) { if (typeof this.passList[resetter] !== 'string') { listWithNewDividers.push(this.passList[resetter])}}
      this.passList = listWithNewDividers
      listWithNewDividers = []
      for (var pass of this.passList) {
        var day = new Date(pass.endUTC * 1000)
        day.setHours(0,0,0,0)
        if (day > lastDay) { listWithNewDividers.push(day.toString()) }
        listWithNewDividers.push(pass)
        lastDay = day
      }
      //remove unneccessary dividers
      this.passList = listWithNewDividers
      listWithNewDividers = []
      var lastType = typeof 'string';
      listWithNewDividers.push(this.passList[0])
      for (var p = 0; p < this.passList.length; p++) {
        if (!(typeof this.passList[p] === 'string' && typeof lastType === 'string')) { 
          listWithNewDividers.push(this.passList[p])
        }
        lastType = this.passList[p]
      }
      this.passList = listWithNewDividers
      listWithNewDividers = []
      var lastShown;
      for (var q = 0; q < this.passList.length; q++) { if (typeof this.passList[q] !== 'string') { if (this.passList[q].isShown) {lastShown = q}}}
      for (var r = 0; r <= lastShown; r++) { listWithNewDividers.push(this.passList[r]) }
      for (var s = lastShown + 1; s < this.passList.length; s++) { if (typeof this.passList[q] !== 'string') { listWithNewDividers.push(this.passList[s]) }}
      this.shownPasses = listWithNewDividers
    },
    async getPassData() {
      for (var id of this.satIDs) {
        var apiURL = ("/rest/v1/satellite/radiopasses/" + id.id + "/" + this.gslocation.lat + "/" + this.gslocation.lng + "/" + this.gslocation.alt + "/" + this.predictdays + "/" + this.minelev).toString()
        fetch(apiURL)
      .then((response) => response.json())
      .then((json) => {
        this.response.push(json)
      })
      }
    }
  },
  mounted() {
    this.isLoaded = false
    var tryResponse = setInterval(() => {
      this.getPassData()
    }, 6000)
    var responseIntervalDone = false
    var loadedIntervalDone = false
    this.waitForResponse = setInterval(() => {
      if (this.response.length == this.satIDs.length) {
        if(!responseIntervalDone) {
          console.log('Got overpass API response...')
          clearInterval(tryResponse)
        }
        responseIntervalDone = true
        this.getTLE()
        this.getWind()
      }
    }, 10)
    this.waitForTLE = setInterval(() => {
      if ((this.satIDs.length == this.moveTLE.length) && responseIntervalDone) {
        console.log('Sorting passes...')
        this.redoLists()
      }
    }, 10)
    var clearOnLoaded = setInterval(() => {
      if (this.isLoaded) {
        if(!loadedIntervalDone) { console.log('OK.') }
        loadedIntervalDone = true
        clearInterval(this.waitForResponse)
        clearInterval(this.waitForTLE)
      }
    }, 10)
    setTimeout(() => {
      clearInterval(clearOnLoaded)
      if (!this.isLoaded) {
        this.isLoaded = true
        console.log('Request timeout.')
        clearInterval(tryResponse)
        clearInterval(this.waitForResponse)
        clearInterval(this.waitForTLE)
        this.response = []
        this.apiError.hasError = true
        this.apiError.errorMessage = 'Error: A server request has timed out. Please check your connection and try again.'
      }
    }, 30000)
    /*    OLD METHOD WITH SIMPLE TIMEOUT, LESS RELIABLE WITH SLOW CONNECTION
    
      setTimeout(() => {this.getTLE()}, 3000);
      setTimeout(()=>{this.redoLists()}, 4000);  // This is extremely sketchy and should be prevented by proper skills :) !!!!
      setTimeout(() => {
        if (this.response.length < 1) { 
          this.apiError.hasError = true
          this.apiError.errorMessage = 'Error getting data: the API server is not responding. Please check your connection and try again.'
        }
      }, 4005) // display no connection error instead of general error for no data at all
      setTimeout(() => {
        if (!this.isLoaded) { 
          this.apiError.hasError = true
          this.isLoaded = true
          this.apiError.errorMessage = 'Error getting data: the API server is not responding. Please check your connection and try again.'
        }
      }, 8000) // display no connection error instead of general error for no data at all */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 84vh;
  border: none; 
  background-color: #03092b;
  background: radial-gradient(circle, rgb(17, 23, 22) 0%, rgb(24, 37, 37) 100%);
  text-align: left;
}
.list-menu {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0 2%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.list-choice {
  width: 96%;
  display: flex;
  justify-content: space-evenly;
}
.list-description {
  position: absolute;
  bottom: 0;
  left: 2%;
  width: 92%;
  display: flex;
  justify-content: space-between;
  color: #7e7e7e;
  background: blue;
  padding: 0 2%;
}
.list-container {
  position: relative;
  width: 90%;
  height: calc(100% - 25px);
  overflow-y: scroll;
  padding: 10px 5%;
  margin: 10px 0;
}
.list-container::-webkit-scrollbar {
  width: 6px;
}
.list-container::-webkit-scrollbar-thumb {
  background: #79c4ab;
  border-radius: 3px;
}
.date-divider {
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: relative;
  color: #7e7e7e;
  font-size: 14px;
  width: 100%;
  text-align: left;
  padding: 0;
  margin: -5px -10px;
}
.date-divider-dash {
  width: calc(100% - 100px);
  height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}
.date-divider-dash-middle {
  background-color: #7e7e7e;
  height: 1px;
  width: 80%;
  margin-right: 5%;
}
.list-head {
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: relative;
  font-size: 15px;
  line-height: 0.8;
  width: 99%;
  text-align: left;
  padding: 15px 10px;
  height: 12px;
  overflow: hidden;
}
.pass-accordion {
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: relative;
  color: #e0e0e0;
  font-size: 20px;
  line-height: 0.8;
  width: 99%;
  text-align: left;
  padding: 15px 10px;
  margin: 15px 10px 15px 0;
  border-radius: 4px;
  transition: height 0.15s ease-out;
  height: 15px;
  overflow: hidden;
  cursor: pointer;
}
.pass-accordion-open {
  transition: height 0.15s ease-out;
  height: 200px;
  margin: 15px 0 -185px 0;
}
.expand-button {
  height: 15px;
  width: auto;
  border: none;
  border-radius: 7.5px;
  box-shadow: 0 0 5px #7ebeb461;
  background-color: #7ebeb431;

}
.button-caret {
  color: #7e7e7e;
  transition: transform 0.15s ease-out;
}
.button-caret-open {
  transition: transform 0.15s ease-out;
  transform: rotate(180deg);
}
.choice-button {
  background: transparent;
  border: 1px solid #7e7e7e;
  border-radius: 3px;
  color: #7e7e7e;
  padding: 10px;
  width: calc(100% / v-bind('response.length + 1') - 40px );
  margin: 0 2px;
  cursor: pointer;
}
.choice-button-current {
  border: 1px solid #79c4ab;
  background-color: #79c4ab;
  color: rgb(17, 23, 22);
}
.accordion-open-content {
  width: 100%;
  height: 185px;
  padding: 0;
  font-size: 15px;
  color: #959595;
}
.flextable-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 5%;
}
.high-wind {
  color: white;
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  0% {
    background-color: #00000000;
  }
  49% {
    background-color: #00000000;
  }
  50% {
    background-color: #aaaa00aa;
  }
  100% {
    background-color: #aaaa00aa;
  }

}
.compass-bg {
  position: absolute;
  top: 0;
  right: 0px;
  height: 185px;
  width: auto;
}
.max-ind-frame {
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
}
.max-ind-img {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 85px;
}
.reload-button {
  width: 40px;
  border: 1px solid #ab976e;
  color: #ab976e;
}
.choice-button:hover {
  box-shadow: 0 0 15px #ffffff48;
  transition: box-shadow 0.15s ease-in;
}
.choice-button:active {
  box-shadow: 0 0 15px #ffffff7f;
}

.epoch-indicator {
  position: absolute;
  bottom: 30px;
  left: 8px;
  font-size: 10px;
  overflow: visible;
}

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes ldio-3r2bqht338w {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-3r2bqht338w div {
  position: absolute;
  width: 145.28px;
  height: 145.28px;
  border: 13.620000000000001px solid #76dcba;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-3r2bqht338w div {
  animation: ldio-3r2bqht338w 0.3831417624521073s linear infinite;
  top: 113.5px;
  left: 113.5px
}
.loadingio-spinner-rolling-yq3hr7owa29 {
  width: 227px;
  height: 227px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-3r2bqht338w {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-3r2bqht338w div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
