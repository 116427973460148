<template>
    <div class="legal-footer">
        <div style="float: right; margin-right: 10px;">
            <span @click="imprintOpen = true" style="text-decoration: underline; cursor: pointer;">Imprint</span>,&nbsp;&nbsp;
            <span @click="prPolicyOpen = true" style="text-decoration: underline; cursor: pointer;">Privacy Policy</span>
        </div>
        <div style="float: left; width: 140px; margin-left: calc(50% - 70px)">&copy; {{copyYear}} WARR e.V. / MOVE</div>
        <div v-if="imprintOpen || prPolicyOpen" class="modal-backdrop"></div>
        <div v-if="imprintOpen" class="modal-container">
            <button @click="imprintOpen = false" class="modal-close">✕</button>
            <h2>Imprint</h2>
             <div style="padding: 0 5%; width: 90%;">
                <h3>Information according to § 5 TMG</h3><br>
                <span style="font-weight: bold">WARR e.V.</span><br>
                c/o TUM Lehrstuhl für Raumfahrttechnik <br>
                Boltzmannstraße 15 <br>
                D-85748 Garching bei München <br>
                Germany <br>
                <br>
                Phone: +49 (89) 289 – 16028 <br>
                E-Mail:  info@warr.de
            </div>
        </div>
        <div v-if="prPolicyOpen" class="modal-container">
            <button @click="prPolicyOpen = false" class="modal-close">✕</button>
            <h2>Privacy Policy Statement for MOCHA (Move Overpass CHecking App)</h2><br>
            <div style="padding: 0 5%; width: 90%;">
                <p>Effective Date: May 23, 2023</p>

                Welcome to MOCHA (Move Overpass CHecking App), owned and operated by WARR e.V. / MOVE. At MOCHA, we value and respect your privacy. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information when you use our web app.
                <ol>
                    <li>
                        Information Collection and Use <br>
                        MOCHA is designed to provide a seamless user experience without storing any user data. We do not collect or store any personally identifiable information (PII) from our users. When you use MOCHA, no personal data, including but not limited to names, addresses, phone numbers, email addresses, or any other information, is retained on our servers or within the app.
                    </li>
                    <li>
                        Log Data <br>
                        Like many other websites and web apps, MOCHA may collect certain non-personal information automatically when you access or use the app. This information, known as log data, may include your device's Internet Protocol (IP) address, browser type, browser version, the pages of MOCHA that you visit, the time and date of your visit, the time spent on those pages, and other statistics. However, this information is collected solely for the purpose of app performance monitoring, debugging, and improving our services. It is not used to identify individual users.
                    </li>
                    <li>
                        Cookies <br>
                        MOCHA does not utilize cookies or similar tracking technologies to collect any personal information or track your activities. We respect your privacy and strive to provide a cookie-free experience.
                    </li>
                    <li>
                        Third-Party Services <br>
                        MOCHA utilizes certain third-party services to enhance its functionality. These services are essential for providing accurate and up-to-date information related to satellite overpasses. The following third-party services are integrated into MOCHA: <br>

                        <p style="margin-left: 15px">a. N2YO API: MOCHA uses the N2YO API (Application Programming Interface) provided by N2YO.com (https://n2yo.com/api) to obtain predictions for satellite overpasses. This API enables MOCHA to deliver real-time data on satellite positions and trajectories, enhancing the accuracy of the information displayed within the app. </p>

                        <p style="margin-left: 15px">b. Cesium Ion Plugin: MOCHA utilizes the Cesium Ion plugin developed by Cesium (https://cesium.com/) for the purpose of displaying live satellite positions on the app's interface. By leveraging the Cesium Ion plugin, MOCHA enhances the visual representation of satellite movements, enabling users to track satellites with greater precision. </p>

                        <p style="margin-left: 15px">c. Celestrak Two-Line Elements (TLEs): MOCHA relies on the Two-Line Elements (TLEs) provided by Celestrak (https://celestrak.org/) to populate its database of satellite orbital data. TLEs are critical for accurately calculating satellite positions and predicting overpasses. By utilizing the TLE data from Celestrak, MOCHA ensures the reliability and integrity of the information it provides to users. </p>

                        It is important to note that while these third-party services are integrated into MOCHA, the privacy and data handling practices of these services are governed by their respective privacy policies. MOCHA does not have control over the data collection, storage, or usage practices of these third parties. We encourage you to review the privacy policies of N2YO.com, Cesium, and Celestrak for more information on how they handle user data. <br>

                        MOCHA remains committed to safeguarding your privacy and limiting the collection and storage of personal information as outlined in our Privacy Policy.
                    </li>
                    <li>
                        Security <br>
                        At MOCHA, we are committed to ensuring the security of your information. While no method of transmission over the Internet or electronic storage is completely secure, we take reasonable precautions to protect any information collected through MOCHA.
                    </li>
                    <li>
                        Children's Privacy <br>
                        MOCHA is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we may have inadvertently collected personal information from a child under the age of 13, please contact us immediately, and we will take steps to delete such information.
                    </li>
                    <li>
                        Changes to this Privacy Policy <br>
                        MOCHA's Privacy Policy may be revised from time to time. We will notify you of any changes by updating the "Effective Date" at the top of this policy. It is your responsibility to review the Privacy Policy periodically and stay informed about any updates.
                    </li>
                    <li>
                        Contact Us <br>
                        If you have any questions or concerns regarding this Privacy Policy or your privacy while using MOCHA, please contact us at <a href="mailto:info@warr.de?subject=MOCHA%20Privacy%20Issue&body=Redirected%20from%20MOCHA%20Privacy%20Statement">info@warr.de</a>.
                    </li>
                </ol>
                <br>
                By using MOCHA, you agree to the terms outlined in this Privacy Policy. If you do not agree with any aspect of this policy, please refrain from using MOCHA.
                <br><br>
                For additional information on the use of personal information by WARR e.V., please refer to the <a href="https://warr.de/privacy-policy/" target="_blank">WARR Privacy Policy</a>.
                <br><br>
                Thank you for trusting MOCHA with your privacy. <br><br>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LegalFooter',
    data() {
        return {
            prPolicyOpen: false,
            imprintOpen: false,
            copyYear: new Date().getFullYear()
        }
    }
}
</script>

<style scoped>
.legal-footer {
    position: fixed;
    bottom: 14px;
    left: 0;
    width: 100%;
    height: 15px;
    font-size: 10px;
    color: #999;
    @media (min-width: 768px) {
        bottom: 2px;
    }
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #222222cb;
}
.modal-container {
    position: fixed;
    top: 5vh;
    left: 5vw;
    height: 90vh;
    width: 90vw;
    background: #ddd;
    color: #222;
    font-size: 15px;
    text-align: left;
    overflow-y: scroll;
}
.modal-close {
    position: absolute;
    right: 0;
    top: 0;
    height: 25px;
    width: 25px;
    background-color: #ccc;
    border: none;
    border-radius: 0 0 0 5px;
    cursor: pointer;
}
h2 {
    width: 100%;
    text-align: center;
}
li {
    margin: 15px;
}
</style>